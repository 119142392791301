<template>
  <div id="index" ref="appRef">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="top" @click="pagesDialogOpen">
          <div class="top-left">
            <span class="text1">{{ dateYear }}</span>
            <span class="text2">{{ dateDay }}</span>
          </div>
          <div class="title">腾泰翼管家</div>
          <div class="top-right">{{ projectName }}</div>
        </div>

        <div class="body">
          <!-- 第一列 -->
          <div class="left">
            <div class="box1">
              <HomeL1 />
            </div>
            <div class="box2">
              <HomeL2 />
            </div>
            <div class="box3">
              <TaskCompletionRate />
            </div>
          </div>
          <!-- 第二列 -->
          <div class="middle">
            <div class="Floating">
              <Floating />
            </div>
            <div class="HomeMap">
              <!-- <HomeMap /> -->
              <iframe src="/#/map" style="width: 100%;height: 100%;border: none;" />
            </div>

          </div>

          <!-- 第三列 -->
          <div class="right">
            <div class="box1">
              <HomeR1 />
            </div>
            <div class="box2">
              <HomeR2 />
            </div>
            <div class="box3">
              <HomeR3 />

            </div>

          </div>
        </div>

      </div>
      <pages-dialog ref="pagesDialogRef" />
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
import PagesDialog from "@/components/PagesDialog.vue";
// import HomeMap from './Home2/HomeMap'
import HomeL1 from './Home2/HomeL1'
import HomeL2 from './Home2/HomeL2'
// import HomeL3 from './Home2/HomeL3'
import HomeR1 from './Home2/HomeR1'
import HomeR2 from './Home2/HomeR2'
import HomeR3 from './Home2/HomeR3'
import Floating from './Home2/Floating'
import TaskCompletionRate from './Home2/TaskCompletionRate.vue'


export default {
  mixins: [drawMixin],
  data() {
    return {
      projectName:"全国",
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
    }
  },
  // HomeMap,
  components: {
    PagesDialog,
    HomeL1, HomeL2, 
    // HomeL3,
    HomeR1, HomeR2, HomeR3,
    Floating,
    TaskCompletionRate
  },

  mounted() {
    document.title = '腾泰 全国'; 
    this.timeFn()
    this.cancelLoading()
  },
  beforeDestroy() {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH : mm : ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    pagesDialogOpen() {
      this.$refs.pagesDialogRef.open()
    },
  }
}
</script>

<style lang="scss" scoped>
#index {
  color: #d3d6dd;
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;

  .host-body {
    width: 100%;
    position: relative;

    .top {
      width: 100%;
      height: 100px;
      background-image: url('https://f.jsttwy.cn/public/title.png');
      background-repeat: no-repeat;
      display: flex;
      text-align: center;
      line-height: 38px;

      .top-left {
        width: 400px;
        color: #ffffff;

        .text1 {
          font-size: 14px;
        }

        .text2 {
          font-size: 16px;
          margin-left: 12px;
        }
      }

      .title {
        width: 1100px;
        font-size: 50px;
        line-height: 100px;
      }

      .top-right {
        width: 400px;
      }
    }

    .body {
      width: 100%;
      height: 950px;
      display: flex;
      padding: 20px 20px 0;
      box-sizing: border-box;

      .left {
        width: 520px;

        div {
          height: 300px;
          width: 100%;
          background-image: url("https://f.jsttwy.cn/public/Smallbox.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .box2 {
          margin: 16px 0 14px;
        }

      }

      .middle {
        width: 800px;
        margin: 0 18px;
        position: relative;

        .Floating {
          position: absolute;
          width: 100%;
          top: 60px;
        }

        .HomeMap{
          margin-top: 220px;
          height: 720px;
        }
      }

      .right {
        width: 520px;

        div {
          height: 300px;
          width: 100%;
          background-image: url("https://f.jsttwy.cn/public/Smallbox.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .box2 {
          margin: 16px 0 14px;
        }

      }
    }


  }
}
</style>
