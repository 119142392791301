<template>
  <div class="task-completion-rate">
      <div class="top">
        <div class="text">任务完成率</div>
        <div class="data">
          <div :class="{ 'box': tab === 0 }" @click="tab=0;loadData()">今日</div>
          <div :class="{ 'box': tab === 1 }" @click="tab=1;loadData()">本月</div>
          <div :class="{ 'box': tab === 2 }" @click="tab=2;loadData()">本年</div>
        </div>
      </div>
      <div id="taskCompletionRate"></div>      
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { taskCompletionRate } from '@/api/bs'
import { getRandomIntInclusive } from '@/utils/index.js'
export default {
  data() {
    return {
      tab: 1,
      id:0,
      myChart:null,
      option: {
        color: [
          '#ff9900',
          '#00aa00'
        ],
        textStyle: {
          color: "rgba(222, 222, 222, 1)"
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {// 图例
          textStyle: {
            color: "rgba(255, 255, 222, 1)"
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['巡检', '维保', '绿化', '保洁', '安保', '后勤']
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        series: [
          {
            name: '总任务',
            type: 'bar',
            data: [18203, 23489, 29034, 104970, 131744, 630230]
          },
          {
            name: '已完成',
            type: 'bar',
            data: [19325, 23438, 31000, 121594, 134141, 681807]
          }
        ]
      },
      loadTimes:0
    }
  },
  mounted() {
    this.loadData()
    setInterval(() => {
          this.switchTab()
    }, getRandomIntInclusive(2,12)* 8 *1000 );
  },
  methods: {
    async loadData() {
      let date = "today"
        switch (this.tab) {
          case 1:
            date = "month"
            break;
          case 2:
            date = "year"
            break;
          default:
            break;
        }
        const res = await taskCompletionRate({ deptId: this.id, date })
        if (res.code === 200) {
            const newOption = {
              xAxis: {},
              series: [
                {}, {}
              ]
            }
            if(this.loadTimes === 0) newOption.xAxis.data = res.data.count.map(v=>v.typeName)
            newOption.series[0].data = res.data.count.map(v=>v.totalCount)
            newOption.series[1].data = res.data.count.map(v=>v.completedCount)
            setTimeout(() => {
                this.run(newOption)
            }, 100);
        }
    },
    switchTab() {
      this.tab++
      this.tab = this.tab % 3
      this.loadData()
    },

    run(newOption) {      
      if(this.loadTimes === 0){
        this.myChart = echarts.init(document.getElementById('taskCompletionRate'));
        this.myChart.setOption(this.option);// 绘制图表
        this.loadTimes++
      }
      this.myChart.setOption(newOption);
    },

  }
}
</script>

<style lang="scss" scoped>
.task-completion-rate {
  height: 100%;
  width: 100%;
  padding: 20px 30px 0;
  box-sizing: border-box;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text {
      width: 193px;
      height: 30px;
      display: flex;
      align-items: center;
      padding-left: 40px;
      font-size: 18px;
      // background: linear-gradient(to right, #0849A5, rgba(255, 255, 255, 0.01));
      background-image: url("https://f.jsttwy.cn/public/Title.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: #fff;
      font-weight: 600;
    }

    .data {
      display: flex;
      color: #ccc;

      div {
        width: 60px;
        height: 30px;
        border: 1px solid #0070C2;
        text-align: center;
        line-height: 30px;
        font-size: 16px;
        margin: 0;
      }

      .box {
        color: #00FEFF;
        background-image: url("https://f.jsttwy.cn/public/box5.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

    }
  }
}

#taskCompletionRate{
  width: 470px;
  height: 238px;
}
</style>
