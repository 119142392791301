<template>
  <div class="HomeL2">
    <div class="body">
      <div class="top">
        <div class="text">近半年考勤人数</div>
        <div class="data">
          <div :class="{ 'box': tab == 1 }" @click="tab = 1; loadData()">考勤</div>
          <div :class="{ 'box': tab == 2 }" @click="tab = 2; loadData()">抽检</div>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom" id="HomeL2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { attendance } from '@/api/bs'
import { getRandomIntInclusive } from '@/utils/index.js'
export default {
  data() {
    return {
      tab: 1,
      dataArr: {
        xdata: ['一月', '二月', '三月', '四月', '五月', '六月'],
        disinfeced: [0, 320, 300, 210, 240, 180],//实际考勤人数, 288
        placeData: [0, 435, 490, 340, 320, 270]//应考勤人数, 360
      }
    }
  },
  mounted() {
    this.loadData()
    setInterval(() => {
      this.switchTab()
    }, getRandomIntInclusive(2, 12) * 12 *1000);
  },
  methods: {
    async loadData() {
      const res = await attendance({ deptId: this.id, type: this.tab })
      if (res.code === 200 && res.data && res.data.actualList) {
        this.dataArr.xdata = res.data.months.map(x => x.slice(0, 7))
        this.dataArr.disinfeced = res.data.actualList
        this.dataArr.placeData = res.data.requiredList
        this.dataArr.disinfeced=  this.dataArr.disinfeced.map(item=>{
          if(item===0){
            return item+0.01
          }else{
            return item
          }
        })
        this.dataArr.placeData=  this.dataArr.placeData.map(item=>{
          if(item===0){
            return item+0.01
          }else{
            return item
          }
        })
        this.$nextTick(() => {
          setTimeout(() => {
            this.GetEcharts()
          }, 100);
        })

      }
    },
    switchTab() {
      this.tab = this.tab === 1 ? 2 : 1
      this.loadData()
    },

    GetEcharts() {
      // mock 数据
  
      // series
      let option = {
        backgroundColor: 'transparent',
        legend: {
          data: ['应考勤人数', '实际考勤人数'],
          textStyle: { fontSize: 14, color: '#CCCCCC' },
          itemWidth: 25,
          itemHeight: 12,
          itemGap: 15,
          right: '5%',
          selectedMode: false
        },
        xAxis: {
          axisTick: { show: true },
          axisLine: { lineStyle: { color: 'rgba(255,255,255, .2)' } },
          axisLabel: { textStyle: { fontSize: 12, color: '#999999' }, },
          data: this.dataArr.xdata
        },
        yAxis: [{
          splitLine: { lineStyle: { color: 'rgba(255,255,255, .6)', type: 'dashed', show: true } },
          axisLine: { show: false, },
          axisLabel: { textStyle: { fontSize: 12, color: '#999999' } },
        }],
        grid: {
          top: '16%', left: '8%', right: '3%', bottom: '10%'
        },
        series: [{
          z: 3,
          name: '上部1',
          type: 'pictorialBar',
          symbolPosition: 'end',
          data: this.dataArr.placeData,
          symbol: 'diamond',
          symbolOffset: [0, '-50%'],
          symbolSize: [24, 16],
          itemStyle: {
            borderColor: 'rgba(47,255,164,.2)',
            borderWidth: 2,
            color: 'rgba(47,255,164,.8)',
          },
        }, {
          z: 1,
          type: 'bar',
          name: '应考勤人数',
          barGap: '-100%',
          barWidth: 24,
          data: this.dataArr.placeData,
          itemStyle: {
            color: {
              type: 'linear',
              x: 0, x2: 1, y: 0, y2: 0,
              colorStops: [{ offset: 0, color: 'rgba(47,255,164,.2)' }, { offset: 0.5, color: 'rgba(47,255,164,0.2)' }, { offset: 0.5, color: 'rgba(49,245,160,.5)' }, { offset: 1, color: 'rgba(49,245,160,.2)' }]
            }
          },
        }, {
          z: 3,
          name: '上部1',
          type: 'pictorialBar',
          symbolPosition: 'end',
          data: this.dataArr.disinfeced,
          symbol: 'diamond',
          symbolOffset: [0, '-50%'],
          symbolSize: [24, 16],
          itemStyle: {
            borderColor: '#1B6BFF',
            borderWidth: 2,
            color: 'rgba(27,107,255,0.8)'
          },
        }, {
          z: 1,
          stack: '总量',
          type: 'bar',
          name: '实际考勤人数',
          barWidth: 24,
          // barGap: '-100%',
          data: this.dataArr.disinfeced,
          itemStyle: {
            color: {
              type: 'linear',
              x: 0, x2: 1, y: 0, y2: 0,
              colorStops: [{ offset: 0, color: 'rgba(27,107,255,0.2)' }, { offset: 0.5, color: 'rgba(27,107,255,.2)' }, { offset: 0.5, color: 'rgba(27,107,255,0.7)' }, { offset: 1, color: 'rgba(27,107,255,.4)' }]
            }
          },
        }]
      }
      var myChart = echarts.init(document.getElementById('HomeL2'));
      // 绘制图表
      myChart.setOption(option);
    },

  }
}
</script>

<style lang="scss" scoped>
.HomeL2 {
  height: 100%;
  width: 100%;
  padding: 20px 30px 0;
  box-sizing: border-box;

  .body {
    width: 100%;
    height: 100%;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        width: 193px;
        height: 30px;
        display: flex;
        align-items: center;
        padding-left: 40px;
        font-size: 18px;
        // background: linear-gradient(to right, #0849A5, rgba(255, 255, 255, 0.01));
        background-image: url("https://f.jsttwy.cn/public/Title.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: #fff;
        font-weight: 600;
      }

      .data {
        display: flex;
        color: #ccc;

        div {
          width: 60px;
          height: 30px;
          border: 1px solid #0070C2;
          text-align: center;
          line-height: 30px;
          font-size: 16px;
          margin: 0;
        }

        .box {
          color: #00FEFF;
          background-image: url("https://f.jsttwy.cn/public/box5.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

      }
    }

    .bottom {
      width: 100%;
      height: 230px;
      padding: 10px 0;
      box-sizing: border-box;
    }
  }
}
</style>
