<template>
  <div class="HomeR2">
    <div class="body">
      <div class="top">
        <div class="text">小区物业费变化趋势</div>
      </div>
      <div class="bottom">
        <div class="bottom" id="HomeR2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { propertyFeeVariationTrend } from '@/api/bs'
export default {
  data() {
    return {
      index: 1,
      lqdata : ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      lqdata1 : ['255', '255', '255', '255', '255', '255', '260', '255', '255', '255', '255', '255',]
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
        const res = await propertyFeeVariationTrend({ deptId: this.id })
        if (res.code === 200 && res.data ) {
            this.lqdata1 = res.data
            setTimeout(() => {
                this.GetEcharts()
            }, 100);
        }
    },
    GetEcharts() {
      let data2 = [{ coords: [] }]
      for (let index = 0; index < this.lqdata1.length; index++) {
        data2[0].coords.push([this.lqdata[index], this.lqdata1[index]],)
      }

      const grid = {
        top: '12%',
        left: '2%',
        right: '4%',
        bottom: '4%',
        containLabel: true
      }
      const xAxis = [
        {
          type: 'category',
          data: this.lqdata,
          boundaryGap: false,
          axisTick: {
            show: false // 不显示坐标轴刻度线
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed',
              color: 'rgba(230, 247, 255, 0.20)'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#ffffff'
            },
          },
          //x底部文字
          axisLabel: {
            textStyle: {
              padding: [4, 0, 0, 0],//表示 [上, 右, 下, 左] 的边距。
              color: '#999999',
              fontSize: 12
            }
          }
        }
      ]

      const yAxis = [
        {
          type: 'value',
          //y右侧文字
          axisLabel: {
            textStyle: {
              color: '#999999',
              fontSize: 12
            }
          },
          // y轴的分割线
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: 'rgba(230, 247, 255, 0.20)',

            }
          }
        }
      ]
      const series = [
        {
          name: '收入统计',
          type: 'line',
          smooth: false,
          symbol: 'circle',//拐点设置为实心
          symbolSize: 2,//拐点大小
          itemStyle: {
            normal: {
              borderColor: 'rgba(42,157,255,.4)',//拐点边框颜色
              borderWidth: 4,//拐点边框大小，
              color: '#1890FF'
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: 'line' // 默认为直线，可选为：'line' | 'shadow'
            }
          },
          lineStyle: {
            normal: {
              width: 1,
              shadowColor: "rgba(4, 40, 114, .4)",
              shadowBlur: 2
            }
          },
          areaStyle: {
            opacity: 1,
            //右下左上
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(4, 40, 114, .1)'
              },
              
              {
                offset: 0.7,
                color: 'rgba(4, 40, 114, 0)'
              }
            ])
          },
          data: this.lqdata1
        },
        {
          type: 'lines',
          coordinateSystem: 'cartesian2d',
          symbolSize: 2,
          polyline: true, // 多线段
          effect: {
            show: true,
            period: 5,
            trailLength: 0.01,
            symbolSize: 8,
            symbol: 'circle',
            color: 'rgba(42,157,255, 1)',
          },
          lineStyle: {
            normal: {
              width: 1,
              opacity: 0
            },
            //   curveness: 0.5,
            //   join: 'round'
          },
          // 光点
          data: data2
        }
      ]

      const option = {
        backgroundColor: 'transparent',
        title: {
          show: true,
          text: '单位：(元）',
          left: '-1%',
          top: '-1%',
          textStyle: {
            color: '#999999',
            // fontStyle: 'oblique',
            fontSize: 12
            //  fontFamily :'Courier New'
          }
        },
        color: ['#1890FF'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        }, grid, xAxis, yAxis, series
      }
      var myChart = echarts.init(document.getElementById('HomeR2'));
      // 绘制图表
      myChart.setOption(option);
    },

  }
}
</script>

<style lang="scss" scoped>
.HomeR2 {
  height: 100%;
  width: 100%;
  padding: 20px 30px 0;
  box-sizing: border-box;

  .body {
    width: 100%;
    height: 100%;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        width: 240px;
        height: 30px;
        display: flex;
        align-items: center;
        padding-left: 40px;
        font-size: 18px;
        // background: linear-gradient(to right, #0849A5, rgba(255, 255, 255, 0.01));
        background-image: url("https://f.jsttwy.cn/public/Title.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: #fff;
        font-weight: 600;
      }
    }

    .bottom {
      width: 100%;
      height: 240px;
      padding: 10px 0;
      box-sizing: border-box;

      .li {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .img {
          height: 100%;
          display: flex;
          align-items: center;

          .name {
            height: 60px;
            width: 200px;
            background-color: #0070C2;
            padding: 10px 0 0 20px;
            box-sizing: border-box;
            font-size: 14px;

            .span1 {
              color: #999999;
              margin-bottom: 8px;
            }
          }


        }


      }
    }
  }
}
</style>
