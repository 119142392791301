<template>
  <div class="HomeL3">
    <div class="body">
      <div class="top">
        <div class="text">小区工单完成率</div>
        <div class="data">
          <div :class="{ 'box': tab == 1 }" @click="tab=1;loadData()">本月</div>
          <div :class="{ 'box': tab == 2 }" @click="tab=2;loadData()">本年</div>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom" id="HomeR3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { orderCompletionRate } from '@/api/bs'
import { getRandomIntInclusive } from '@/utils/index.js'
export default {
  data() {
    return {
      tab: 1,
      lqdata: [
        { "value": 98, "name": "保安" },
        { "value": 50, "name": "绿化" },
        { "value": 30, "name": "保洁" },
        { "value": 24, "name": "维保" },
        { "value": 34, "name": "巡检" }
      ]
    }
  },
  mounted() {
    this.loadData()
    setInterval(() => {
          this.switchTab()
    }, getRandomIntInclusive(2,12) * 9 *1000 );
  },
  methods: {
    async loadData() {
        const res = await orderCompletionRate({ deptId: this.id, date: this.tab === 1 ? 'month' : 'year' })
        if (res.code === 200 && res.data ) {
            this.lqdata = Object.entries(res.data).map(item => {
                return {
                    name: item[0],
                    value: item[1]*100,
                }
            })
            setTimeout(() => {
                this.GetEcharts()
            }, 100);
        }
    },
    switchTab() {
        this.tab = this.tab===1?2:1
        this.loadData()
    },

    GetEcharts() {
      let series = [];
      let pieDatas = this.lqdata;
      let maxRadius = 100, barWidth = 8, barGap = 10;
      let barColor = ['#FFDA10', '#58E883', '#9104FF', '#00F5F6', '#085FFF'];
      pieDatas.map((item, i) => {
        series.push({
          type: 'pie',
          clockWise: true, //顺时加载
          hoverAnimation: false, //鼠标移入变大
          radius: [(maxRadius - i * (barGap + barWidth)) + '%', (maxRadius - (i + 1) * barWidth - i * barGap) + '%'],
          center: ["40%", "50%"],
          label: {
            show: false
          },
          itemStyle: {
            borderRadius: 100,
            label: {
              show: false,
            },
            labelLine: {
              show: false
            },
            borderWidth: 5,
          },

          data: [{
            value: item.value,
            name: item.name,
            itemStyle: {
              color: barColor[i]
            },
            label: { //饼图图形上的文本标签
              normal: {
                show: true,
                position: 'inner', //标签的位置
                textStyle: {
                  fontWeight: 400,
                  fontSize: 10 ,//文字的字体大小
                  color:'#fff'
                },
                formatter: '{d}%' //显示%
              },
            },
          }, {
            value: 100 - item.value,
            name: '',
            itemStyle: {
              color: "transparent",
            },
            tooltip: {
              show: false
            },
            hoverAnimation: false
          },]
        })
        series.push({
          name: '',
          type: 'pie',
          silent: true,
          z: 0,
          clockWise: true, //顺时加载
          hoverAnimation: false, //鼠标移入变大
          radius: [(maxRadius - i * (barGap + barWidth)) + '%', (maxRadius - (i + 1) * barWidth - i * barGap) + '%'],
          center: ["40%", "50%"],
          label: {
            show: false
          },
          itemStyle: {
            label: {
              show: false,
            },
            labelLine: {
              show: false
            },
          },
          data: [{
            value: 1,
            itemStyle: {
              color: "rgba(235, 239, 247, 0.4)",
            },
            tooltip: {
              show: false
            },
            hoverAnimation: false
          }]
        });
      })
      const option = {
        grid: {
          top: '0%',
          bottom: '0%',
          left: "0%",
          containLabel: false
        },
        backgroundColor: 'transparent',
        tooltip: {
          show: false,
        },
        xAxis: [{
          show: false
        }],
        yAxis: [{
          type: 'category',
          inverse: true,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            inside: true,
            textStyle: {
              color: "#fff",
              fontSize: 14,
            },
            show: false
          },
          data: pieDatas.map(x => x.value + '%')
        }],
        legend: {
          left: 'right',
          top: '1%',
          align: 'left',
          show: true,
          itemWidth: 10,
          itemHeight: 10,
          formatter: function (name) {
            // let res = pieDatas.filter(n => n.name === name)
            // console.log(res);
            return `${name} `
          },
          textStyle: {
            fontSize: 14,
            color: '#CCCCCC',
            padding: [0, 0, 0, 8]
          },
        },

        series: series,
      }
      var myChart = echarts.init(document.getElementById('HomeR3'));
      // 绘制图表
      myChart.setOption(option);
    },

  }
}
</script>

<style lang="scss" scoped>
.HomeL3 {
  height: 100%;
  width: 100%;
  padding: 20px 30px 0;
  box-sizing: border-box;

  .body {
    width: 100%;
    height: 100%;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        width: 210px;
        height: 30px;
        display: flex;
        align-items: center;
        padding-left: 40px;
        font-size: 18px;
        // background: linear-gradient(to right, #0849A5, rgba(255, 255, 255, 0.01));
        background-image: url("https://f.jsttwy.cn/public/Title.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: #fff;
        font-weight: 600;
      }

      .data {
        display: flex;
        color: #ccc;

        div {
          width: 60px;
          height: 30px;
          border: 1px solid #0070C2;
          text-align: center;
          line-height: 30px;
          font-size: 16px;
          margin: 0;
        }

        .box {
          color: #00FEFF;
          background-image: url("https://f.jsttwy.cn/public/box5.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

      }
    }

    .bottom {
      width: 100%;
      height: 240px;
      padding: 10px 0;
      box-sizing: border-box;

      .li {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .img {
          height: 100%;
          display: flex;
          align-items: center;

          .name {
            height: 60px;
            width: 200px;
            background-color: #0070C2;
            padding: 10px 0 0 20px;
            box-sizing: border-box;
            font-size: 14px;

            .span1 {
              color: #999999;
              margin-bottom: 8px;
            }
          }


        }


      }
    }
  }
}
</style>
