<template>
  <div class="FloatingS">
    <div class="li1 li">
      <div class="img">
        <img src="https://f.jsttwy.cn/public/icon4.png" alt="">
      </div>
      <div class="text">
        <div class="box1">全国小区总数</div>
        <div class="box2">{{lqdata.allCommunityTotalCount}}</div>
        <div class="box3"><span>相比昨日</span><span class="span">+{{lqdata.todayNewCommunityCount}}</span></div>
      </div>
    </div>
    <div class="li2 li">
      <div class="img">
        <img src="https://f.jsttwy.cn/public/icon5.png" alt="">
      </div>
      <div class="text">
        <div class="box1">工作人员人数</div>
        <div class="box2">{{lqdata.allCommunitiesTotalCount}}</div>
        <div class="box3"><span>相比昨日</span><span class="span">+{{lqdata.todayNewCommunitiesCount}}</span></div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as echarts from 'echarts';
import { getCommunityInfo } from '@/api/bs'
export default {
  data() {
    return {
      lqdata: {}
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
        const res = await getCommunityInfo({ deptId: this.id })
        if (res.code === 200 && res.data ) {
            this.lqdata = res.data
        }
    },
  }
}
</script>

<style lang="scss" scoped>
.FloatingS {
  width: 100%;
  height: 130px;
  background-image: url("https://f.jsttwy.cn/public/icon6.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  .li1{
    padding: 0 0 0 40px;
    box-sizing: border-box;
  }
  .li2{
    padding: 0 40px 0 0;
    box-sizing: border-box;
  }

  .li {
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
    top: -24px;

    .img {
      height: 100%;

      img {
        width: 130PX;
        height: 124PX;
      }

      margin-right: 10%;
    }

    .text {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0 14px;
      box-sizing: border-box;
      color: #FFFFFF;

      .box1 {
        font-size: 14px;
        height: 32px;
        background-image: url("https://f.jsttwy.cn/public/bgd.png");
        background-size: 200% 100%;
        background-position: center center;
        line-height: 32px;
        background-repeat: no-repeat;
      }

      .box2 {
        color: #00FEFF;
        font-size: 36px;
        height: 40px;
        line-height: 40px;
      }

      .box3 {
        font-size: 12px;
        color: #ccc;
        .span {
          color: #FF2D2E;
        }
      }
    }
  }
 
}</style>
