<template>
  <div class="HomeR1">
    <div class="header">
      <div class="text">小区物业费排行榜</div>
      <div class="data">
        <div :class="{ 'box': tab == 1 }" @click="tab=1;loadData()">本月</div>
        <div :class="{ 'box': tab == 2 }" @click="tab=2;loadData()">本年</div>
      </div>
    </div>
    <div class="body" id="HomeR1"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { propertyFeeRanking } from '@/api/bs'
import { getRandomIntInclusive } from '@/utils/index.js'
export default {
  data() {
    return {
      tab: 1,
      lqdata: [
        { name: 'xxx事项1', value: 500, total:0, received:0, amount:0 },
        { name: 'xxx事项2', value: 211, total:0, received:0, amount:0 },
        { name: 'xxx事项3', value: 165, total:0, received:0, amount:0 },
        { name: 'xxx事项4', value: 145, total:0, received:0, amount:0 },
        { name: 'xxx事项5', value: 128, total:0, received:0, amount:0 },
      ]
    }
  },
  mounted() {
    this.loadData()
    setInterval(() => {
      this.switchTab()
    }, getRandomIntInclusive(12,32)*1000 );
  },
  methods: {
    async loadData() {
      this.lqdata = []
      const res = await propertyFeeRanking({ deptId: this.id, date: this.tab === 1 ? 'month' : 'year', pageNum:1, pageSize:10 })
      if (res.code === 200 && res.rows && res.total) {
          if(res.rows.length>5) res.rows.length = 5
          this.lqdata = res.rows.map((item) => {
              return {
                  name: item.communityName,
                  value: item.collectionRate,
                  total: item.totalCount,
                  received: item.actualCount,
                  amount: item.totalAmount
              }
          })
          setTimeout(() => {
              this.GetEcharts()
          }, 100);
      }
    },
    switchTab() {
        this.tab = this.tab===1?2:1
        this.loadData()
    },

    GetEcharts() {
      const grid = { top: "6%", left: '0%', right: '10%', bottom: "2%", }
      // xAxis
      const xAxis = {
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitArea: { show: false },
        axisLine: {
          show: false,
        },
      }

      // yAxis
      const yAxis = {
        inverse: true,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          textStyle: {
            color: '#fff',
            fontSize:22,
            padding: [-15, 0, 25, 18],
          },
          formatter(value, index) {
            let str = '', num = 'TOP' + (index + 1)
            if (index === 0) {
              str = '{a| ' + num + '}{title| ' + value + '}'
            } else if (index === 1) {
              str = '{b| ' + num + '}{title| ' + value + '}'
            } else if (index === 2) {
              str = '{c| ' + num + '}{title| ' + value + '}'
            } else {
              str = '{d| ' + num + '}{title| ' + value + '}'
            }
            return str
          },
          rich: {
            a: {
              color: '#fff',
              borderColor: '#EE6F7C',
              borderWidth: 1,
              borderRadius: [0, 10, 10, 0],
              padding: [1, 8, 3, 0],
              backgroundColor: '#E41437',
            },
            b: {
              color: '#fff',
              borderColor: '#FFCF5F',
              borderWidth: 1,
              borderRadius: [0, 10, 10, 0],
              padding: [1, 8, 3, 0],
              backgroundColor: '#EC6A14',
            },
            c: {
              color: '#fff',
              borderColor: '#00E8FF',
              borderWidth: 1,
              borderRadius: [0, 10, 10, 0],
              padding: [1, 8, 3, 0],
              backgroundColor: '#ECBA14',
            },
            d: {
              color: '#fff',
              borderColor: '#1A90FF',
              borderWidth: 1,
              borderRadius: [0, 10, 10, 0],
              padding: [1, 8, 3, 0],
              backgroundColor: '#085FFF',
            },
            title: {
              padding: [0, 0, 0, 6],
            },
          },
          align: 'left',
        },
        data: this.lqdata.map((item) => item.name + "  - 应收" + item.total + " 已收" + item.received),
      }

      // series
      const series = [
        {
          type: 'pictorialBar',
          symbol: 'rect',
          symbolRotate: 30,
          symbolRepeat: 'fixed',
          symbolClip: true,
          symbolOffset: [0, -1.5],
          symbolSize: [2, 12],
          symbolMargin: '3',
          itemStyle: {
            normal: {
              color: '#000726',
            },
          },
          label: {
            show: true,
            color: '#fff',
            fontSize: 12,
            // offset: [-0.9, -1.5],
            position: 'right',
            formatter(params) {
              let result = ''
              switch (params.dataIndex) {
                case 0:
                  result = '{img|}{index0|' + (params.value*100).toFixed(1)+"%" + '}'
                  break
                case 1:
                  result = '{img|}{index1|' + (params.value*100).toFixed(1)+"%" + '}'
                  break
                case 2:
                  result = '{img|}{index2|' + (params.value*100).toFixed(1)+"%" + '}'
                  break
                default:
                  result = '{img|}{index3|' + (params.value*100).toFixed(1)+"%" + '}'
                  break
              }
              return result
            },
            rich: {
              img: {
                height: 18,
                width: 20,
                // backgroundColor: { image: '../..//assets/icon.png' },//这个图片自己切，这里上传不了（加了一个尾巴的形状）
              },
              unit: {
                color: '#fff',
                fontSize: 11,
              },
              index0: {
                color: '#ee6f7c',
                padding: [-2, 2, 0, 0],
              },
              index1: {
                color: '#ffcf5f',
                padding: [-2, 2, 0, 0],
              },
              index2: {
                color: '#00e8ff',
                padding: [-2, 2, 0, 0],
              },
              index3: {
                color: '#fff',
                padding: [-2, 2, 0, 0],
              },
            },
          },
          symbolBoundingData: Math.max(...this.lqdata.map((item) => item.value)) * 1.6,//1.3
          data: this.lqdata.map((item) => item.value),
          z: 2,
        },
        {
          type: 'bar',
          barWidth: 10,
          data: this.lqdata.map((item) => item.value),
          itemStyle: {
            normal: {
              color: '#00F7F0',
            },
          },
          z: 1,
        },
        {
          type: 'bar',
          barGap: '-125%', // 设置外框粗细
          data: this.lqdata.map(() => Math.max(...this.lqdata.map((item) => item.value)) * 1.6),//1.3
          barWidth: 15,
          itemStyle: {
            color: 'none',
            borderColor: 'rgba(64, 245, 245, 0.5)',
          },
          z: 0,
        },
      ]
      let option = {
        backgroundColor: 'transparent',
        xAxis, yAxis, series, grid
      }
      var myChart = echarts.init(document.getElementById('HomeR1'));
      // 绘制图表
      myChart.setOption(option);
    },

  }
}
</script>

<style lang="scss" scoped>
.HomeR1 {
  height: 100%;
  width: 100%;
  padding: 20px 0 0 30px;
  box-sizing: border-box;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;

    .text {
      width: 193px;
      height: 30px;
      display: flex;
      align-items: center;
      padding-left: 40px;
      font-size: 18px;
      // background: linear-gradient(to right, #0849A5, rgba(255, 255, 255, 0.01));
      background-image: url("https://f.jsttwy.cn/public/Title.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      color: #fff;
      font-weight: 600;
    }

    .data {
      display: flex;
      color: #ccc;

      div {
        width: 60px;
        height: 30px;
        border: 1px solid #0070C2;
        text-align: center;
        line-height: 30px;
        font-size: 16px;
        margin: 0;
      }

      .box {
        color: #00FEFF;
        background-image: url("https://f.jsttwy.cn/public/box5.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

    }
  }

  .body {
    width: 100%;
    height: 240px;
    padding: 10px 0;
    box-sizing: border-box;

    .li {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .img {
        height: 100%;
        display: flex;
        align-items: center;

        .name {
          height: 60px;
          width: 200px;
          background-color: #0070C2;
          padding: 10px 0 0 20px;
          box-sizing: border-box;
          font-size: 14px;

          .span1 {
            color: #999999;
            margin-bottom: 8px;
          }
        }


      }


    }
  }

}
</style>
