<template>
  <div class="HomeL1">
    <div class="body">
      <div class="top">
        <div class="text">物业服务</div>
        <div class="data">
          <div :class="{ 'box': tab == 1 }" @click="tab = 1; loadData()">本月</div>
          <div :class="{ 'box': tab == 2 }" @click="tab = 2; loadData()">本年</div>
        </div>
      </div>
      <div class="bottom" v-if="dataList.length">
        <div class="li" v-for="(item, index) in dataList" :key="index">
          <div class="img">
            <img :src="item.src" alt="" srcset="">
            <div class="name">
              <div class="num"><span class="span1">{{ item.type }}数量：</span> <span class="span2">{{ item.num }}</span>
              </div>
              <div class="percentage"><span class="span1">完成率:</span> <span class="span2">{{ item.percentage + '%'
              }}</span>
              </div>
            </div>
          </div>
          <div class="texts">
            <div class="qiu">
              <div :id="'qiu' + (index + 1)" style="width: 60px;height: 60px;"></div>
            </div>
            <div class="text">好评率</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import 'echarts-liquidfill'
import { propertyService } from '@/api/bs'
import { getRandomIntInclusive } from '@/utils/index.js'
export default {
  data() {
    return {
      tab: 1,
      dataList: [],
      mydata: [],
      chartValue: 0
    }
  },
  mounted() {
    this.loadData()
    setInterval(() => {
      this.switchTab()
    }, getRandomIntInclusive(2, 12) * 10000);
  },
  methods: {
    async loadData() {
      this.dataList= []
      this.mydata= []
      const res = await propertyService({ deptId: this.id, date: this.tab === 1 ? 'month' : 'year' })
      if (res.code === 200 && res.data && res.data.length) {
        this.mydata = res.data
        this.dataList = res.data.map((item, index) => {
          return {
            type: item.type,
            src: "https://f.jsttwy.cn/public/icon" + (index + 1) + ".png",
            num: item.totalCount,
            percentage: new Intl.NumberFormat().format(item.finishingRate * 100)
          }
        })
        setTimeout(() => {
         this.GetEcharts()
        }, 100);
      }
    },
    switchTab() {
      this.tab = this.tab === 1 ? 2 : 1
      this.loadData()
    },

    GetEcharts() {
      let option = {
        series: [{
          type: 'liquidFill',
          radius: '75%',
          center: ['50%', '50%'],
          color: [
            {
              type: "linear",
              x: 0,
              y: 1,
              x2: 0,
              y2: 0,
              colorStops: [
                {
                  offset: 1,
                  color: ["rgba(81, 126, 255,1)"], // 0% 处的颜色
                },
                {
                  offset: 0,
                  color: ["rgba(127, 58, 255,1)"], // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            }
          ],
          data: [this.chartValue], // data个数代表波浪数
          backgroundStyle: {
            borderWidth: 1,
            color: 'rgba(103, 108, 115)'
          },
          label: {
            normal: {
              formatter: (this.chartValue * 100).toFixed(0) + '%',
              textStyle: {
                fontSize: 12,
                color: "rgba(90, 185, 255)",
              }
            }
          },
          outline: {
            show: false,
          }
        }
        ]
      }

      if (this.mydata[0]) {
        var myChart1 = echarts.init(document.getElementById('qiu1'));
        // 绘制图表
        this.chartValue = this.mydata[0].praiseRate
        option.series[0].data = [this.chartValue]
        option.series[0].label.normal.formatter = (this.chartValue * 100).toFixed(0) + '%'
        myChart1.setOption(option);
      }
      if (this.mydata[1]) {
        var myChart2 = echarts.init(document.getElementById('qiu2'));
        // 绘制图表
        this.chartValue = this.mydata[1].praiseRate
        option.series[0].data = [this.chartValue]
        option.series[0].label.normal.formatter = (this.chartValue * 100).toFixed(0) + '%'
        myChart2.setOption(option);
      }
      if (this.mydata[2]) {
        var myChart3 = echarts.init(document.getElementById('qiu3'));
        // 绘制图表
        this.chartValue = this.mydata[2].praiseRate
        option.series[0].data = [this.chartValue]
        option.series[0].label.normal.formatter = (this.chartValue * 100).toFixed(0) + '%'
        myChart3.setOption(option);
      }

    },

  }
}
</script>

<style lang="scss" scoped>
.HomeL1 {
  height: 100%;
  width: 100%;
  padding: 20px 30px 0;
  box-sizing: border-box;

  .body {
    width: 100%;
    height: 100%;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text {
        width: 193px;
        height: 30px;
        display: flex;
        align-items: center;
        padding-left: 40px;
        font-size: 18px;
        // background: linear-gradient(to right, #0849A5, rgba(255, 255, 255, 0.01));
        background-image: url("https://f.jsttwy.cn/public/Title.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: #fff;
        font-weight: 600;
      }

      .data {
        display: flex;
        color: #ccc;

        div {
          width: 60px;
          height: 30px;
          border: 1px solid rgba(93, 69, 255);
          text-align: center;
          line-height: 30px;
          font-size: 16px;
          margin: 0;
        }

        .box {
          color: #00FEFF;
          background-image: url("https://f.jsttwy.cn/public/box5.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

      }
    }

    .bottom {
      width: 100%;
      height: 290px;
      padding: 10px 0 0 0px;
      box-sizing: border-box;

      .li {
        width: 100%;
        height: 74px;
        display: flex;
        align-items: center;

        .img {
          height: 70px;
          display: flex;
          align-items: center;


          .name {
            height: 56px;
            width: 200px;
            background-image: url("https://f.jsttwy.cn/public/icon7.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            padding: 10px 0 0 26px;
            box-sizing: border-box;
            font-size: 14px;
            position: relative;
            left: -14px;
            top: -2px;

            .span1 {
              color: #999999;
              margin-bottom: 8px;
            }
          }


        }


      }
    }

    .texts {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .qiu {
        width: 62px;
        height: 62px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid rgba(5, 116, 174);
        border-radius: 100%;
        border-top: none;
        border-left: none;
      }

      .text {
        width: 100px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        color: #D9FFFF;
        font-size: 14px;
        background-image: url("https://f.jsttwy.cn/public/bgd.png");
        background-size: 140% 100%;
        background-position: center center;
      }
    }
  }
}
</style>
